
import { ref, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Game, GameHistoryItem } from '@/core/models';
import { gameHistory } from '@/core/game-history';
import { coreUtil } from '@/core/core-util';
import { coreBus } from '@/core/core-bus';
import { tracking } from '@/core/tracking';
import SButton from '@/components/SButton.vue';

export default defineComponent({
    components: { SButton },

    setup() {
        console.log('--------///////////1111');
        tracking.event('game-history-opened', {});
        const items = ref<GameHistoryItem[]>([]);
        const selected = ref<GameHistoryItem | null>(null);
        const router = useRouter();

        gameHistory.getItems().then((d) => {
            items.value = d;
        });

        const gameName = (game: Game) => {
            return coreUtil.gameDisplayName(game).replace('Solitaire', '');
        };

        const selectItem = (item: GameHistoryItem) => {
            selected.value = item;
        };

        const formatTime = (time: number) => {
            return coreUtil.timeFormat(time);
        };

        const replay = () => {
            if (selected.value) {
                const path = coreUtil.getGameRoute(selected.value.game);
                const gameId = selected.value.gameId.toString();
                router.push(`${path}/${gameId}`);
            }
        };

        const challengeFriend = () => {
            if (selected.value) {
                coreBus.showChallengeFriendDialogCmd$.next({
                    game: selected.value.game,
                    gameId: selected.value.gameId,
                });
            }
        };

        const leaderboard = () => {
            if (selected.value) {
                coreBus.showLeaderBoardDialogCmd$.next({
                    game: selected.value.game,
                    gameId: selected.value.gameId,
                });
            }
        };

        return {
            items,
            selected,
            gameName,
            selectItem,
            formatTime,
            replay,
            challengeFriend,
            leaderboard,
        };
    },
});
