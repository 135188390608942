<template>
    <div class="p-6 mx-auto flex-grow">
        <div class="history-wrapper">
            <h2>Game History</h2>
            <div>
                Bellow you can see your game history. you can replay a game, view the leaderboard or
                challenge a friend
            </div>
            <div v-show="!selected">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    @click="selectItem(item)"
                    class="my-3 p-4 shadow bg-indigo-900 cursor-pointer"
                >
                    <div>{{ gameName(item.game) }} #{{ item.gameId }}</div>
                    <div class="text-sm text-gray-400">{{ item.date.toLocaleString() }}</div>
                </div>
            </div>
            <div v-if="selected">
                <div class="my-3 p-4 shadow bg-indigo-900">
                    <div class="text-xl">{{ gameName(selected.game) }} #{{ selected.gameId }}</div>
                    <div class="text-gray-500 text-sm">{{ selected.date.toLocaleString() }}</div>
                    <div class="m-5">
                        <div>Score: {{ selected.score }}</div>
                        <div>Moves: {{ selected.moves }}</div>
                        <div>Time: {{ formatTime(selected.time) }}</div>
                        <div class="mt-3">
                            <s-button @click="replay" color="blue">Replay this game</s-button>
                        </div>
                        <div class="mt-3">
                            <s-button @click="leaderboard" color="blue">Leaderboard</s-button>
                        </div>
                        <div class="mt-3">
                            <s-button @click="challengeFriend" color="blue"
                                >Challenge a friend</s-button
                            >
                        </div>
                    </div>
                    <div>
                        <s-button @click="selected = null">Back to list</s-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import { Game, GameHistoryItem } from '@/core/models';
import { gameHistory } from '@/core/game-history';
import { coreUtil } from '@/core/core-util';
import { coreBus } from '@/core/core-bus';
import { tracking } from '@/core/tracking';
import SButton from '@/components/SButton.vue';

export default defineComponent({
    components: { SButton },

    setup() {
        console.log('--------///////////1111');
        tracking.event('game-history-opened', {});
        const items = ref<GameHistoryItem[]>([]);
        const selected = ref<GameHistoryItem | null>(null);
        const router = useRouter();

        gameHistory.getItems().then((d) => {
            items.value = d;
        });

        const gameName = (game: Game) => {
            return coreUtil.gameDisplayName(game).replace('Solitaire', '');
        };

        const selectItem = (item: GameHistoryItem) => {
            selected.value = item;
        };

        const formatTime = (time: number) => {
            return coreUtil.timeFormat(time);
        };

        const replay = () => {
            if (selected.value) {
                const path = coreUtil.getGameRoute(selected.value.game);
                const gameId = selected.value.gameId.toString();
                router.push(`${path}/${gameId}`);
            }
        };

        const challengeFriend = () => {
            if (selected.value) {
                coreBus.showChallengeFriendDialogCmd$.next({
                    game: selected.value.game,
                    gameId: selected.value.gameId,
                });
            }
        };

        const leaderboard = () => {
            if (selected.value) {
                coreBus.showLeaderBoardDialogCmd$.next({
                    game: selected.value.game,
                    gameId: selected.value.gameId,
                });
            }
        };

        return {
            items,
            selected,
            gameName,
            selectItem,
            formatTime,
            replay,
            challengeFriend,
            leaderboard,
        };
    },
});
</script>

<style scoped>
.history-wrapper {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}
</style>
