import { GameHistoryItem } from '@/core/models';
import { set, get } from 'idb-keyval';
import { orderBy } from 'lodash';

class GameHistory {
    private maxHistory = 100;

    private key = 'game-history';

    private items: GameHistoryItem[] | null = null;

    async addItem(item: GameHistoryItem) {
        if (!this.items) {
            this.items = await this.load();
        }
        this.items.push(item);
        if (this.items.length > this.maxHistory) {
            this.items.shift();
        }
        return this.save();
    }

    async getItems() {
        if (!this.items) {
            this.items = await this.load();
        }
        return orderBy(this.items, (x) => x.date, 'desc');
    }

    private async save() {
        return set(this.key, this.items);
    }

    private async load(): Promise<GameHistoryItem[]> {
        const items = (await get(this.key)) as GameHistoryItem[] | null;
        return items ? items.filter((x) => !!x) : [];
    }
}

export const gameHistory = new GameHistory();
